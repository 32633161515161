/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-new-hearing-aids-the-better-choice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-new-hearing-aids-the-better-choice",
    "aria-label": "are new hearing aids the better choice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are new hearing aids the better choice?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Televisions, computers, clothing or toys – there is hardly anything that can‘t be bought second hand. Even used hearing aids are frequently offered for sale, mostly by private individuals. Whether or not the purchase of such used hearing systems is really worthwhile will be explained in the following section."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What we do know for certain is that a ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "hearing aid is a high-tech product"), " that substantially improves the wearer’s hearing ability. Individuals who are ", React.createElement(_components.a, {
    href: "/hearing-aids/prices",
    className: "c-md-a"
  }, "planning to purchase a hearing aid device"), " wish to regain a certain quality of life. With hearing aids acting so instrumentally in improving personal well-being, the question then arises as to whether money should be saved by purchasing used hearing aids at the expense of the outcome."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following provides important information regarding the purchase of second-hand versus new hearing aids, respectively. Arguments for and against used hearing aids are presented here to allow you to assess your personal needs. Our attentive hearing aid experts are here for you to answer additional questions and will give you free and non-binding advice with regard to hearing aids. Simply give us a call or use the provided form. We look forward to talking with you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hygiene-and-used-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hygiene-and-used-hearing-aids",
    "aria-label": "hygiene and used hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hygiene and used hearing aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/cleaning-hearing-aids.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wearing hearing aids implies the device is in contact with abundant skin and sweat. Therefore, it is possible that such residue from the previous wearer still remains on the used hearing aids. It is difficult to eliminate residue that accumulated on the device over the years, since not all of it is readily visible with the naked eye, and hardly on a photograph taken to showcase and sell the used devices. Naturally, this problem is resolved when wearing your own, new hearing aids. This gives you control over the cleaning routine, which has a major impact on the service life of the hearing system.")), "\n", React.createElement(ButtonCta, {
    copy: "GET THE BEST SOLUTION",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "used-hearing-aids--fitting-by-the-audiologist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#used-hearing-aids--fitting-by-the-audiologist",
    "aria-label": "used hearing aids  fitting by the audiologist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Used hearing aids – Fitting by the audiologist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A professional fitting of the hearing aids by an audiologist is essential for hearing aids to function properly. When this is omitted, the potential of hearing aids is by no means exhausted, often remaining at the level of a sound processor. A sound processor is limited to merely amplifying ambient noise without filters, completely neglecting the specific hearing situation. Ultimately, the fitting of the hearing aids by an audiologist will allow you to determine the precise value of hearing aids. At the audiologist’s office, you will always receive extensive and professional advice to help you ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "find the hearing aid that best meets your requirements"), ". You basically select the device together with the expert, who will always be at your side to answer questions along the way. These service features are not applicable if you purchase hearing aids second-hand."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "advantage-of-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#advantage-of-new-hearing-aids",
    "aria-label": "advantage of new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Advantage of new hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It can be concluded that the advantages of a new device outweigh those of a used one."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com works together with more than ", React.createElement(_components.a, {
    href: "/about-us/network",
    className: "c-md-a"
  }, "200 specialists in the field of hearing aids all over Canada"), " and offers exclusively high-quality, new hearing aids by renowned manufacturers. We ensure that your hearing aids are a perfect match and improve your quality of life. Contact us and receive some non-binding advice from our experts."), "\n", React.createElement(ButtonCta, {
    copy: "GET THE BEST HEARING AID NOW",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
